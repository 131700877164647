@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

body {
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  background-color: rgb(0, 0, 0);
  background-image: url(https://i.ibb.co/jvx5kQ8/Morein4-BG.jpg);
  background-position: top top;
  background-size: cover;
  margin: 0;
  background-repeat: no-repeat;
}

.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgb(49, 49, 177);
  background: linear-gradient(
    0deg,
    rgb(49, 130, 177) 0%,
    rgb(49, 68, 177, 0.8) 50%,
    rgba(35, 22, 82, 0.2) 100%
  );
}

.logo {
  padding-top: 50px;
}

.container {
  width: 100%;
  margin: 2% auto;
}
.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
}

@media (min-width: 768px) {
  .container {
    width: 765px;
  }
  h1 {
    font-size: 58px;
  }
}
